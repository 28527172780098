$my-theme-primary: (
  50: #e3f2f6,
  100: #b8dfe8,
  200: #89cad9,
  300: #59b5ca,
  400: #36a5be,
  500: #1295b3,
  600: #108dac,
  700: #0d82a3,
  800: #0a789a,
  900: #05678b,
  A100: #b8e9ff,
  A200: #85d9ff,
  A400: #52caff,
  A700: #38c2ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$my-theme-accent: (
  50: #fceae4,
  100: #f8cabb,
  200: #f4a78e,
  300: #f08461,
  400: #ec693f,
  500: #e94f1d,
  600: #e6481a,
  700: #e33f15,
  800: #df3611,
  900: #d9260a,
  A100: #ffffff,
  A200: #ffd5d0,
  A400: #ffa79d,
  A700: #ff9084,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$primary: #1295b3;
$secondary: $gray-600; //#e94f1d;
$danger: #dc3545;
$warning: #ffc107;
$success: #198754;
$light: #f8f9fe;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "warning": $warning,
  "success": $success,
  "light": $light,
);

@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";
@import "@ng-select/ng-select/themes/default.theme.css";

@mixin trim($numLines: null) {
  @if $numLines !=null {
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

@import "intl-tel-input/build/css/intlTelInput.css";

.iti__flag {
  background-image: url("assets/intl/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("assets/intl/flags@2x.png");
  }
}

// SCROLLBAR
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(33, 33, 33, 0.8); // #1295b3;
}

//*********************
//*********************

@font-face {
  font-family: "Larsseit";
  src: url("assets/fonts/Larsseit/3672D8_B_0.eot");
  src: url("assets/fonts/Larsseit/3672D8_B_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Larsseit/3672D8_B_0.woff2") format("woff2"),
    url("assets/fonts/Larsseit/3672D8_B_0.woff") format("woff"), url("assets/fonts/Larsseit/3672D8_B_0.ttf") format("truetype");
}

//*********************
//*********************

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Larsseit";
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
}

.extra-info {
  color: grey;
}
.h-0 {
  height: 0;
}
.overflow-y {
  overflow-y: auto !important;
}

.flex-even {
  flex: 1;
}

.w-30px {
  width: 30px;
}

.custom-invalid {
  border: 3px solid #dc3545;
  border-radius: 0.375rem;
  &:hover {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
}

.booking-code {
  padding: 2px;
  border: 2px solid #b53e16;
  background-color: #f2f2f2;
  margin-bottom: 0;
  height: 42px;
}

.data-missing-value {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

.btn.btn-primary {
  color: white;
  &:hover,
  &:active,
  &:disabled {
    color: white;
  }
}

.pdf-icon {
  width: 45px;
  margin-right: 10px;
}
.user-logged-text {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.icon-color-green {
  color: #198754;
}
.icon-color-red {
  color: #dc3545;
}

.dm-container {
  position: relative;
}

.main-container {
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;

  .inner-title {
    flex-shrink: 1;
    text-align: center;
    margin: 0;
    padding: 10px 0;
  }

  .inner-container {
    flex-grow: 1;

    .inner-section {
      padding: 20px;
    }

    .form-group {
      margin: 5px 0;
    }

    .text-trim {
      @include trim(3);
    }
  }

  .inner-footer {
    flex-shrink: 1;

    .dm-container {
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-shrink: 1;
    }

    button {
      width: 50%;
      height: 60px;
      border-radius: 0;
      border-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;

      &:hover {
        color: white;
      }

      &:disabled {
        background-color: var(--bs-secondary);
      }

      i {
        font-size: 1.5rem;
        margin-top: -2px;
      }

      span {
        font-size: 1rem;
        margin-top: -10px;
      }
    }
  }

  .page-404 {
    .page-404-title {
      font-size: 10rem;
    }
    .page-404-body {
      text-align: center;
      font-size: 1.5rem;
    }
  }

  .page-deleted {
    .page-deleted-title {
      margin-top: 30px;
      font-size: 2rem;
    }
    .page-deleted-body {
      text-align: center;
      font-size: 1.5rem;
    }
  }
}

.mobile {
  display: block;
}
.desktop {
  display: none;
}

@media screen and (min-width: 900px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .main-container {
    margin: 0;
    .title-with-languages {
      max-width: 1140px;
      width: 100%;
      margin: auto;
    }
    .inner-title {
      flex-shrink: 1;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    .dm-container {
      //max-width: 1140px;
      width: 100%;
      margin: auto;
      padding-right: 3rem;
      padding-left: 3rem;
    }

    .inner-container {
      .inner-section {
        padding: 20px;
      }

      .form-group {
        margin: 5px 0;
      }

      .text-trim {
        @include trim(3);
      }
    }

    .lg-50 {
      max-width: 50%;
    }
    .lg-30 {
      max-width: 30%;
    }

    .inner-footer {
      display: block;
      min-height: none;
      padding-right: 1rem;
      padding-left: 1rem;
      margin-top: 1rem;
      margin-bottom: 2rem;

      .dm-container {
        height: 60px;
        display: flex;
        flex-shrink: 1;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 3rem;
        padding-left: 3rem;
      }

      .footer-delete-button-left {
        width: 15em;
        position: absolute;
        left: 3rem;
        height: 100%;
        border-radius: 0;
        border-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
      }

      .footer-button {
        width: 10em;
        height: 100%;
        border-radius: 0;
        border-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;

        &:hover {
          color: white;
        }

        &:disabled {
          background-color: var(--bs-secondary);
        }

        i {
          font-size: 1.5rem;
          margin-top: -2px;
        }

        span {
          font-size: 1rem;
          margin-top: -10px;
        }
      }
    }

    .slot-content .slot {
      max-width: 600px;
      margin: 0 auto 1rem auto;
    }
    .add-reason-content {
      max-width: 600px;
      justify-content: center;
      align-items: start;
    }
  }
}

.product-undefined {
  color: var(--bs-danger);
}

.products-table {
  th {
    padding: 0;
    font-weight: 500;
  }

  .ng-select .ng-select-container,
  .ng-select.ng-select-single .ng-select-container {
    /* min-height: 50px;
    height: 50px; */
    border-radius: 0rem;
  }
}

.ng-select {
  min-height: 42px;
}
.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  height: 100%;
  border: none;
  border-radius: 0.375rem;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e9ecef;
}
.edit-select-truck-type.ng-select > .ng-select-container {
  min-height: 40px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 50%;
  transform: translateY(-50%);
}

.product-card-disabled {
  background-color: #e9ecef;
}

.alert-disabled {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.custom-file-button {
  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }

    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: #dde0e3;
      cursor: pointer;
    }
  }
}

.icon-arrow {
  height: 40px;
  fill: #05678b;
  cursor: pointer;

  &.disabled {
    fill: $gray-300;
    cursor: default;
  }
}

.icon-arrow-left {
  transform: rotate(180deg);
}

.slot-content {
  .slot {
    cursor: pointer;
    margin: 0 0 1rem;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    position: relative;
    background-color: #d3d3d4;
    border: #bcbebf;
    border-radius: 0.375rem;
    color: #141619;

    &.selected {
      background-color: var(--bs-primary);
      //background-color: #1295b3 !important;
      color: white !important;
    }

    &.out-of-prebooking {
      //color: #d9260a;
      color: #141619;
      background-color: #d3d3d4;
      border: #bcbebf; //background: repeating-linear-gradient(45deg, #e8e9ea, #e8e9ea 10px, #d3d3d4 10px, #d3d3d4 20px);
    }

    &.out-of-prebooking-selected {
      //color: #d9260a;
      color: white !important;
      background-color: #dc3545;
    }
  }
}

.text-message-danger {
  font-size: 0.8rem;
}

.btn-50-mobile {
  min-height: 50px;
  color: white;
  font-size: 1.2rem;

  &:hover {
    color: white;
  }
}

.btn-group-50-mobile {
  label {
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    color: white;
  }
}

.btn-footer-mobile {
  height: 60px;
  flex-grow: 1;
  margin: 0;

  &.left {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.right {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    color: white;

    &:hover {
      color: white;
    }
  }
}

.dropdown-menu {
  z-index: 3000;
  width: 100%;
}

.search-warehouse {
  &:focus {
    box-shadow: none;
  }
}

input {
  min-height: 42px;
}

.ngb-dp-month {
  width: 100%;

  .ngb-dp-weekdays,
  .ngb-dp-week {
    justify-content: space-evenly;
  }

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.2rem;

    [ngbDatepickerDayView] {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1.2rem;
    }
  }
}

.chip {
  padding: 12px 0;
  border-radius: 25px;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  margin-top: 5px;
  margin-bottom: 5px;

  .chip-icon-left {
    margin-left: 14px;
    margin-right: 22px;
  }

  .chip-label {
    margin-right: 14px;
  }

  .chip-icon-right {
    margin-right: 14px;
    cursor: pointer;
  }
}

.title-with-languages {
  /*  height: 50px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .flags-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 999;
  }
}

//*********************
//*********************

.stepper-wrapper {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    display: none;
  }
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 9px;
  left: -50%;
  z-index: 2;
}
.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 9px;
  left: 50%;
  z-index: 2;
}
.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}
.stepper-item.active {
  font-weight: bold;

  & .step-counter {
    background-color: #393;
  }
}
.stepper-item.completed .step-counter {
  background-color: #393;
}
.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #393;
  width: 100%;
  top: 9px;
  left: 50%;
  z-index: 3;
}
.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

//*********************
//*********************
.grecaptcha-badge {
  bottom: 100px !important;
  @media (max-width: 768px) {
    width: 0 !important;
  }
}
